var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meters"},[_c('v-row',[_c('v-col',[_c('breadcrumb',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-col',{staticClass:"text-right"},[(_vm.can('main-meter-create'))?_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddMeterDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTableLargePlus)+" ")]),_vm._v(" Add Main Meter ")],1):_vm._e()],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Main Meters "),_c('v-spacer'),_c('v-col',{staticClass:"col-md-4"},[_c('search-input',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.meters,"loading":_vm.isMetersLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.meters.length > 0)?_c('tbody',_vm._l((items),function(meter){return _c('tr',{key:meter.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onMeterClick(meter.id)}}},[_c('td',[_vm._v(_vm._s(meter.number))]),_c('td',[_vm._v(_vm._s(meter.station.name))]),_c('td',[(meter.mode == 1)?_c('span',[_vm._v(_vm._s(meter.type.name))]):_c('span',[_vm._v("Manual")])]),(_vm.can('main-meter-edit') || _vm.can('main-meter-delete'))?_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.can('main-meter-edit'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.editMeter(meter)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.can('main-meter-delete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.showDeleteMeterDialog(meter.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1):_vm._e()])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMetersLoading),expression:"!isMetersLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No meters found ")])])])]}}])}),_c('v-divider'),_c('v-pagination',{staticClass:"mt-1",attrs:{"length":_vm.pagination.pageCount,"total-visible":8},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('add-main-meter-dialog',{attrs:{"show-dialog":_vm.showAddMeterDialog,"meter":_vm.meter},on:{"close":function($event){_vm.showAddMeterDialog = false
      _vm.meter = {}},"meter-added":function($event){return _vm.getMeters()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteMeter()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }